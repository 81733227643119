import type { AnalyticsDataType } from '@/types/analytics';

const sendEvent = ({ eventName }: AnalyticsDataType) => {
	// NOTE: Add this for ability to transfer extended data
	const data = {
		eventName,
	};

	return data;
};

export { sendEvent };
