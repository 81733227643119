import { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';

export const keycloakConfig: KeycloakConfig = {
	url: process.env.NEXT_PUBLIC_KEYCLOAK_URL,
	realm: process.env.NEXT_PUBLIC_KEYCLOAK_REALM,
	clientId: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID,
};

export const keycloakInitOptions: KeycloakInitOptions = {
	onLoad: 'login-required',
	checkLoginIframe: false,
	pkceMethod: 'S256',
};
