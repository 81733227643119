const getInitialLang = (): string => {
	const isClient = typeof window !== 'undefined';
	if (!isClient) {
		return 'ru';
	}

	let locale = window.localStorage.getItem('LANGUAGE');

	if (!locale && navigator.languages.length) {
		const browserLocale = navigator.languages[0];
		locale = browserLocale.startsWith('ru') ? 'ru' : 'en';
	}

	return locale || 'ru';
};

export default getInitialLang;
