/* eslint-disable */
import { YM_ID } from '@/config';
import type { AnalyticsDataType } from '@/types/analytics';

const yaMetrika = (fn) => (data: AnalyticsDataType) => {
	const analytics = fn(data);
	const { eventName } = analytics;

	if (window && window.ym) {
		window.ym(YM_ID, 'reachGoal', eventName /* , analytics */);
	}
};

export { yaMetrika };
