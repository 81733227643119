import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

type PageLoaderProps = {
	className?: string;
};

const PageLoader: React.FC<PageLoaderProps> = ({ className }) => (
	<div className={clsx(cn.wrapper, className)}>
		<div className={cn.loader} />
	</div>
);

export default PageLoader;
