import cookie from 'cookie';
import type { IncomingMessage } from 'http';

export function parseCookies(req?: IncomingMessage) {
	if (!req?.headers) {
		return {};
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
	return cookie.parse(req.headers.cookie || '');
}
