import React, { ReactNode } from 'react';

import { AnalyticsContextProvider } from './context';
import { combineFuncs, sendEvent } from './helpers';
import { googleAnalytics, yaMetrika } from './metrics';

type AnalyticsProviderProps = {
	children: ReactNode;
};

const AnalyticsProvider = ({ children }: AnalyticsProviderProps): JSX.Element => (
	<AnalyticsContextProvider
		value={{
			sendEvent: (data) => combineFuncs(yaMetrika(sendEvent), googleAnalytics(sendEvent))(data),
		}}
	>
		{children}
	</AnalyticsContextProvider>
);

export { AnalyticsProvider };
