import 'normalize.css';
import '@/assets/styles/font.sass';
import '@/assets/styles/index.sass';
import '@/assets/styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';

import { SSRAuthClient, SSRCookies, SSRKeycloakProvider } from '@react-keycloak/ssr';
import { observer } from 'mobx-react-lite';
import { AppContext, AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { appWithI18Next, useSyncLanguage } from 'ni18n';
import React from 'react';

import PageLoader from '@/components/shared/utillities/loaders/PageLoader';
import { AnalyticsProvider } from '@/context/Analytics';
import { StoreProvider } from '@/hooks/useStore';
import { parseCookies } from '@/utils/cookies';
import getInitialLang from '@/utils/international/getInitialLang';
import { keycloakConfig, keycloakInitOptions } from '@/utils/keycloak';

import { ni18nConfig } from '../../ni18n.config';

const Providers = dynamic(() => import('@/components/shared/providers/Providers'), {
	loading: () => <PageLoader />,
});

interface IProps {
	cookies: unknown;
}

interface MyApp<P = Record<string, unknown>> extends React.FC<AppProps & P> {
	getInitialProps?: ({ Component, ctx }: AppContext) => Promise<P>;
}

const App: MyApp<IProps> = observer(({ Component, pageProps, cookies }) => {
	const handleLoadingCheck = React.useCallback((authClient: SSRAuthClient) => !authClient.authenticated, []);
	const {
		query: { locale = getInitialLang() },
	} = useRouter();

	useSyncLanguage(locale as string);

	return (
		<SSRKeycloakProvider
			keycloakConfig={keycloakConfig}
			persistor={SSRCookies(cookies)}
			initOptions={keycloakInitOptions}
			LoadingComponent={<PageLoader />}
			isLoadingCheck={handleLoadingCheck}
		>
			<Providers>
				<AnalyticsProvider>
					<StoreProvider {...pageProps}>
						<Component {...pageProps} />
					</StoreProvider>
				</AnalyticsProvider>
			</Providers>
		</SSRKeycloakProvider>
	);
});

// eslint-disable-next-line @typescript-eslint/require-await
App.getInitialProps = async (context) => ({
	cookies: parseCookies(context?.ctx?.req),
});

export default appWithI18Next(App, ni18nConfig);
